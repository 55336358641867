<template>
  <div>
      <!-- Page Title Section -->
      <section class="page-title">
          <div class="pattern-layer-one" :style="{backgroundImage: 'url('+bgPattern5+')'}"></div>
          <div class="pattern-layer-two" :style="{backgroundImage: 'url('+bgPattern6+')'}"></div>
          <div class="icon-layer-one" :style="{backgroundImage: 'url('+icon5+')'}"></div>
          <div class="icon-layer-two" :style="{backgroundImage: 'url('+icon5+')'}"></div>
          <div class="icon-layer-three" :style="{backgroundImage: 'url('+icon6+')'}"></div>
          <div class="icon-layer-four" :style="{backgroundImage: 'url('+iconPattern1+')'}"></div>
          <div class="icon-layer-five" :style="{backgroundImage: 'url('+iconPattern2+')'}"></div>
          <div class="auto-container">
              <h1>About us</h1>
              <ul class="page-breadcrumb">
                  <li>
                      <router-link :to="{name: 'Home'}">home</router-link>
                  </li>
                  <li>About us</li>
              </ul>
          </div>
      </section>
      <!-- End Page Title Section -->

      <!-- About Section -->
      <section class="about-section">
          <div class="auto-container">
              <div class="row clearfix">

                  <!-- Image Column -->
                  <div class="image-column col-lg-6 col-md-12 col-sm-12">
                      <div class="inner-column">
                          <div class="icon-layer-one" :style="{backgroundImage: 'url('+icon1+')'}"></div>
                          <div class="icon-layer-two" :style="{backgroundImage: 'url('+icon2+')'}"></div>
                          <div class="icon-layer-three" :style="{backgroundImage: 'url('+icon3+')'}"></div>
                          <div class="icon-layer-four" :style="{backgroundImage: 'url('+icon4+')'}"></div>
                          <div class="image">
                              <img src="../../assets/images/resource/about.png" alt="" />
                          </div>
                          <!-- Video Box Two -->
                          <div class="video-box">
                              <figure class="video-image">
                                  <img src="../../assets/images/resource/video-image.jpg" alt="">
                              </figure>
                              <a href="https://www.youtube.com/watch?v=kxPCFljwJws" class="lightbox-image overlay-box"><span class="flaticon-play-arrow"><i class="ripple"></i></span></a>
                          </div>
                      </div>
                  </div>

                  <!-- Content Column -->
                  <div class="content-column col-lg-6 col-md-12 col-sm-12">
                      <div class="inner-column">
                          <!-- Sec Title -->
                          <div class="sec-title">
                              <div class="title">ABOUT THE AGENCY</div>
                              <h2>We have been known to give investors the better choice.</h2>
                          </div>
                          <div class="text">
                              With over 153,000 investments under our management, $5 billion+ in assets under our
                              administration, over 11 industry awards, we have made Trust Earns the safe haven for investors who
                              want to trust their financial partner to be capable of helping them reach their financial
                              goals, and in due time, without the fear of disappointments.
                          </div>
                          <div class="author-box">
                              <div class="box-inner">
                                  <div class="author-image">
                                      <img src="../../assets/images/resource/author-1.jpg" alt="" />
                                  </div>
                                  Company Director
                                  <strong>Elena Hachaturianc</strong>
                                  <span class="signature">
									<img src="../../assets/images/icons/signature.png" alt="" />
								</span>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </div>
      </section>
      <!-- End About Section -->

      <!-- Counter Section -->
      <section class="counter-section">
          <div class="auto-container">
              <div class="inner-container">
                  <!-- Fact Counter -->
                  <div class="fact-counter">
                      <div class="row clearfix">

                          <!-- Column -->
                          <div class="column counter-column col-lg-4 col-md-6 col-sm-12">
                              <div class="inner">
                                  <div class="icon"><i class="fa fa-user"></i></div>
                                  <div class="content">
                                      <div class="count-outer count-box">
                                          <span class="count-text" data-speed="2500" data-stop="49649">0</span>+
                                      </div>
                                      <h4 class="counter-title">Investors</h4>
                                  </div>
                              </div>
                          </div>

                          <!-- Column -->
                          <div class="column counter-column col-lg-4 col-md-6 col-sm-12">
                              <div class="inner">
                                  <div class="icon"><i class="fa fa-university"></i></div>
                                  <div class="content">
                                      <div class="count-outer count-box alternate">
                                          <span class="count-text" data-speed="2000" data-stop="5">0</span>M+
                                      </div>
                                      <h4 class="counter-title">Transactions</h4>
                                  </div>
                              </div>
                          </div>

                          <!-- Column -->
                          <div class="column counter-column col-lg-4 col-md-6 col-sm-12">
                              <div class="inner">
                                  <div class="icon"><i class="fa fa-users"></i></div>
                                  <div class="content">
                                      <div class="count-outer count-box">
                                          <span class="count-text" data-speed="2000" data-stop="32">0</span>+
                                      </div>
                                      <h4 class="counter-title">Partners</h4>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>

              </div>
          </div>
      </section>
      <!-- End Counter Section -->

      <!-- Accordion Box -->
      <div class="accordion-boxed">
          <div class="inner-box">
              <div class="row clearfix">

                  <!-- Image Column -->
                  <div class="image-column col-lg-5 col-md-12 col-sm-12">
                      <div class="inner-column">
                          <div class="image">
                              <img src="../../assets/images/resource/faq-1.jpg" alt="" />
                          </div>
                      </div>
                  </div>

                  <!-- Content Column -->
                  <div class="content-column col-lg-7 col-md-12 col-sm-12">
                      <div class="inner-column">
                          <h4>KickStart Solutions.</h4>

                          <!-- Accordian Box -->
                          <ul class="accordion-box">

                              <!-- Block -->
                              <li class="accordion block active-block">
                                  <div class="acc-btn active"><div class="icon-outer"><span class="icon icon-plus fa fa-plus"></span> <span class="icon icon-minus fa fa-minus"></span></div>01. Who are we?</div>
                                  <div class="acc-content current">
                                      <div class="content">
                                          <div class="accordian-text">
                                              At TrustEarn we provide a full investment service focused on the bitcoin and cryptocurrency market We are among the best platforms to invest and grow your bitcoin and other cryptocurrency
                                          </div>
                                      </div>
                                  </div>
                              </li>

                              <!-- Block -->
                              <li class="accordion block">
                                  <div class="acc-btn"><div class="icon-outer"><span class="icon icon-plus fa fa-plus"></span> <span class="icon icon-minus fa fa-minus"></span></div>02. How do i create an account?</div>
                                  <div class="acc-content">
                                      <div class="content">
                                          <div class="accordian-text">
                                              Registration process is very easy and will take a few moments to complete Simply click Register button and fill in all the required fields
                                          </div>
                                      </div>
                                  </div>
                              </li>

                              <!-- Block -->
                              <li class="accordion block">
                                  <div class="acc-btn"><div class="icon-outer"><span class="icon icon-plus fa fa-plus"></span> <span class="icon icon-minus fa fa-minus"></span></div>03. How do i make a deposit?</div>
                                  <div class="acc-content">
                                      <div class="content">
                                          <div class="accordian-text">
                                              To deposit funds in your trading account is quick and simple
                                              For your convenience you may choose one of the several available
                                              deposit methods To make a successful deposit login into your dashboard
                                              and click on the DEPOSIT button, enter the required amount for your selected package
                                              and follow the outlined steps to complete your transaction
                                          </div>
                                      </div>
                                  </div>
                              </li>

                              <!-- Block -->
                              <li class="accordion block">
                                  <div class="acc-btn"><div class="icon-outer"><span class="icon icon-plus fa fa-plus"></span> <span class="icon icon-minus fa fa-minus"></span></div>04. How long does my deposit take before it can reflect on my TrustEarn account dashboard?</div>
                                  <div class="acc-content">
                                      <div class="content">
                                          <div class="accordian-text">
                                              Your deposit will be reflected immediately once it is confirmed on the blockchain network
                                          </div>
                                      </div>
                                  </div>
                              </li>

                          </ul>

                      </div>
                  </div>

              </div>
          </div>
      </div>
      <!-- End Accordion Box -->


  </div>
</template>

<script>
    import icon1 from "../../assets/images/icons/icon-1.png";
    import icon2 from "../../assets/images/icons/icon-2.png";
    import icon3 from "../../assets/images/icons/icon-3.png";
    import icon4 from "../../assets/images/icons/icon-4.png";
    import bgPattern5 from "../../assets/images/background/pattern-5.png";
    import bgPattern6 from "../../assets/images/background/pattern-6.png";
    import icon5 from "../../assets/images/icons/icon-5.png";
    import icon6 from "../../assets/images/icons/icon-6.png";
    import iconPattern1 from "../../assets/images/icons/pattern-1.png";
    import iconPattern2 from "../../assets/images/icons/pattern-2.png";

    export default {
        name: 'About',
        data(){
            return {
                icon1, icon2, icon3, icon4,
                bgPattern5, bgPattern6,
                icon5, icon6, iconPattern1,
                iconPattern2
            }
        },
        mounted() {
            if($('.accordion-box, .accordion-box-two').length){
                $(".accordion-box, .accordion-box-two").on('click', '.acc-btn', function() {

                    var outerBox = $(this).parents('.accordion-box, .accordion-box-two');
                    var target = $(this).parents('.accordion');

                    if($(this).hasClass('active')!==true){
                        $(outerBox).find('.accordion .acc-btn').removeClass('active');
                    }

                    if ($(this).next('.acc-content').is(':visible')){
                        return false;
                    }else{
                        $(this).addClass('active');
                        $(outerBox).children('.accordion').removeClass('active-block');
                        $(outerBox).find('.accordion').children('.acc-content').slideUp(300);
                        target.addClass('active-block');
                        $(this).next('.acc-content').slideDown(300);
                    }
                });
            }
            if($('.count-box').length){
                $('.count-box').appear(function(){

                    var $t = $(this),
                        n = $t.find(".count-text").attr("data-stop"),
                        r = parseInt($t.find(".count-text").attr("data-speed"), 10);

                    if (!$t.hasClass("counted")) {
                        $t.addClass("counted");
                        $({
                            countNum: $t.find(".count-text").text()
                        }).animate({
                            countNum: n
                        }, {
                            duration: r,
                            easing: "linear",
                            step: function() {
                                $t.find(".count-text").text(Math.floor(this.countNum));
                            },
                            complete: function() {
                                $t.find(".count-text").text(this.countNum);
                            }
                        });
                    }

                },{accY: 0});
            }
        }
    }
</script>
